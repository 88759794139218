
import React, { useEffect, useState } from "react";
import "./banner.scss";
import "react-toastify/dist/ReactToastify.css";
import Modal from 'react-bootstrap/Modal';
import Environment from "../../../utils/Environment";
import axios from "axios";
import { toast } from "react-toastify";

const Banner = () => {
  const [email, setEmail] = useState("");
  const api_url = Environment.api_url;
  const [emailerrorregister, setEmailErrorRegister] = useState("");
  const [error, setError] = useState(null);

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }
  const userSign = (e) => {
    if (email.length === 0) {
      setEmailErrorRegister("Email is Required");
    } else if (!isValidEmail(email)) {
      setEmailErrorRegister("Email is invalid");
    }
    else {
      var data = JSON.stringify({
        email: email,

      });
      var config = {
        method: "post",
        url: `${api_url}/temp/gems-trade `,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          handleShow()
          // toast.success("Signup Successfull")
          setEmail("")
          setEmailErrorRegister("")
        })
        .catch(function (error) {

          // toast.error("Incorrect email or password!");

        });
    }
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <section className="main-banner">
        <img src="\assets\coming-bg.png" alt="img" className="img-fluid coming-bg" />
        <div className="custom-container">
          <div className="main-content">
            <img src="\logo.svg" alt="img" className="img-fluid banner-logo" />
            <h4>launching soon</h4>
            <h6>A faster, better and more secure crypto exchange with all the features you need to trade your crypto assets</h6>
            <p>Sign up today and be the first to know about the latest updates</p>
            <div onClick={userSign} className="option-field">
              <input value={email} onChange={(e) => setEmail(e.target.value)} type="text" placeholder="Enter your email address" />

              <a href="#">SUBMIT <svg xmlns="http://www.w3.org/2000/svg" width="13" height="10" viewBox="0 0 13 10" fill="none">
                <path d="M1.5 4.92023H11.5M11.5 4.92023L7.5 0.920227M11.5 4.92023L7.5 8.92023" stroke="#0B0B0B" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
              </svg></a>
            </div>
            {emailerrorregister ? (
              <p className="text-danger mt-2">{emailerrorregister}</p>
            ) : null}
          </div>

        </div>
      </section>


      <Modal className="success-modal" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Signed successfully!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src="\assets\check.svg" alt="img" className="img-fluid" />
          <h6>Thank you for signing up! <br />
            We will keep you posted</h6>
          <a onClick={handleClose}>CLOSE</a>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Banner;
